import React, {useEffect} from "react";
import PublicHeader from "../../pages/layouts/public_header";
import AppFooter from "../../pages/layouts/app_footer";
import "./blog.css";
import {useNavigate, useParams} from "react-router-dom";
import {useBlogAPI, BlogPost} from "../../api/blog_api";
import {format} from "date-fns";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BlogPostProps {

}
const BlogPostPage: React.FC<BlogPostProps> = (props) => {
    const [blogPost, setBlogPost] = React.useState<BlogPost|null>(null);
    const blogAPI = useBlogAPI();
    const {slug} = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        blogAPI.getBlogPost(slug || "unknown")
            .then((post) => {
                setBlogPost(post);
            })
    }, [slug]);
    return (
        <main className="page_container blog-container">
            <PublicHeader theme="dark"/>
            <div className="blog-post-page flex flex-grow flex-col max-w-[90%] m-auto">
                <div className="blog-post-header text-xxxl-serif italic">
                    <h1>{blogPost?.title || "Loading ..."}</h1>
                    <div className="text-s-sans text-center">{format(blogPost?.published_at || new Date(), "dd MMMM yyyy")}</div>
                </div>
                <div className="hline">&nbsp;</div>
                <div className="blog-content" dangerouslySetInnerHTML={{__html: blogPost?.content || ""}}>
                </div>
            </div>

            <div className="blog-footer">
                <div>
                    We’re igniting the next creative<br/>
                    renaissance with infrastructure<br/>
                    to power authenticity.
                </div>
                <div className="relative">
                    <button onClick={() => navigate("/register")}>
                        Get Started
                    </button>
                </div>
            </div>
            <AppFooter/>
        </main>
    );
}
export default BlogPostPage;