import React, {useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import "./image_details.css";
import {Textarea} from "../../../common/components/ui/textarea";
import {Button} from "../../../common/components/ui/button";
import {IconArrowRight, IconXMark, Sparkles} from "../../../common/icons/icons";
import CardRadioGroup from "../../../common/components/card_radio_group";
import {FormField} from "../../../common/components/ui/form";
import ImageCard from "./image_card";
import {useGenerativeAPI} from "../../../api/generative_ai_api";
import {useFileAPI} from "../../../api/file_api";
import {uniqueId} from "lodash";
import loading from "../../../common/components/ui/loading.gif";

const ImageDetails = ({ form, errors, selectedModels, navigateToModel, aspectRatios }: { form: any, errors: any, selectedModels: any, navigateToModel: (step: any) => void, aspectRatios: any }) => {
    const [starterImage, setStarterImage] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const { enhancePrompt } = useGenerativeAPI();
    const { uploadFile } = useFileAPI();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { setValue, control } = useFormContext();
    const objectId = form.watch("id") ?? uniqueId();

    const handleEditImage = (id: number) => {
        navigateToModel(id);
    }

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const models = []
        if (selectedModels.product) {
            models.push(selectedModels.product.id)
        }
        if (selectedModels.brand) {
            models.push(selectedModels.brand.id)
        }
        if (selectedModels.talent) {
            models.push(selectedModels.talent.id)
        }
        const response = await enhancePrompt(form.getValues("prompt"), models);
        if (response && response.enhanced) {
            setValue("prompt", response.enhanced);
        }
        setIsLoading(false);
    }

    const browseForStarterImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: File[] = event.target.files ? Array.from(event.target.files) : [];
        if (files.length > 0) {
            uploadFile(
                {
                    files: files,
                    is_public: true,
                    generate_thumbnail: true,
                    object_id: objectId,
                    object_type: "starter_image",
                },
                (progressEvent) => {
                    setUploadProgress((progressEvent.progress || 0) * 100);
                }
            ).then((response) => {
                setStarterImage(files[0]);
                form.setValue("image_file_id", response[0].id);
                setUploading(false);
            });

        }
    };

    const removeStarterImage = () => {
        setStarterImage(null);
        form.setValue("image_file_id", null);
    };


    return (
        <div className="space-y-12">
            <div>
                <Textarea
                    placeholder="Enter image prompt here"
                    className="bg-gray-100 text-black mb-6"
                    value={form.watch("prompt")}
                    onChange={(e) => setValue("prompt", e.target.value)}
                />
                {isLoading ? <Button className="w-40" onClick={enhanceTextPrompt} type="button" variant="primary">
                    <img className="h-4" src={loading} alt="Loading..." />
                </Button> :
                    <Button onClick={enhanceTextPrompt} type="button" variant="primary" className="hover:bg-black hover:text-white hover:fill-white"><><Sparkles />Rewrite prompt</></Button>}

            </div>
            <div>
                <p className="subheader">Model selections (optional)</p>
                <div className="flex flex-col md:flex-row gap-14 flex-wrap">
                    <ImageCard imageModel={
                        selectedModels.product
                            ? selectedModels.product
                            : { name: "Product model", group_name: "None selected" }
                    } forImageDetailsPage imageAction={() => handleEditImage(0)} />
                    <ImageCard imageModel={
                        selectedModels.brand
                            ? selectedModels.brand
                            : { name: "Brand model", group_name: "None selected" }
                    } forImageDetailsPage imageAction={() => handleEditImage(1)} />
                    <ImageCard imageModel={
                        selectedModels.talent
                            ? selectedModels.talent
                            : { name: "Talent model", group_name: "None selected" }
                    } forImageDetailsPage imageAction={() => handleEditImage(2)} />
                </div>
            </div>
            <div>
                <p className="subheader">Starter image (optional)</p>
                <div className="flex gap-3">
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                    />
                    <Button variant="primary-negative" type="button" onClick={browseForStarterImage}>
                        <>Browse<IconArrowRight fill="white" /></>
                    </Button>
                    {starterImage && <div className="bg-gray-100 flex items-center rounded h-14 gap-3">
                        <img src={URL.createObjectURL(starterImage)} alt={starterImage.name} className="h-full w-auto" />
                        {starterImage.name}<div onClick={removeStarterImage} className="p-2"><IconXMark /></div></div>}
                </div>
            </div>
            <div>
                <p className="subheader">Aspect ratio</p>
                <FormField
                    control={control}
                    name="aspect_ratio"
                    render={({ field }) => (
                        <CardRadioGroup field={field} options={aspectRatios} activeOption={field.value} theme="lite" className={"radiogroup"} />
                    )}
                />
            </div>

        </div>
    )

}

export default ImageDetails;

