import React, { useEffect, useState } from "react";
import SubHeader from "../../layouts/sub_header";
import { useGenerativeAPI } from "../../../api/generative_ai_api";
import { GenerateMediaResult } from "../models/image_generator";
import "./image_details.css";
import Heading from "../../../common/components/heading";
import { DialogModal } from "../../../common/components/ui/dialog_modal";
import { Button } from "../../../common/components/ui/button";
import { IconArrowRight, EditPromptIcon, TrashIcon, MiniVerifiedLogo } from "../../../common/icons/icons";
import { useNavigate } from "react-router-dom";

const MyGeneratedImages = () => {
  const [myGeneratedImages, setMyGeneratedImages] = useState<GenerateMediaResult[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<GenerateMediaResult | null>(null);
  const { getMyImages, deleteImage } = useGenerativeAPI();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyImages = async () => {
      try {
        const response = await getMyImages();
        setMyGeneratedImages(response);
      } catch (error) {
        console.error("Error fetching generative output", error);
      }
    };
    fetchMyImages();

  }, []);

  const openImageDetails = (image: GenerateMediaResult) => () => {
    setSelectedImage(image);
    setOpenModal(true);
  }

  const deleteMyImage = (taskQueueId: string) => async () => {
    try {
      await deleteImage(taskQueueId);

      setOpenModal(false);
      setSelectedImage(null);


      // const response = await getMyImages();
      // setMyGeneratedImages(response);
    } catch (error) {
      console.error("Error deleting image", error);
    }
  }

  return (
    <div className="page_content">

      <DialogModal
        isOpen={openModal}
        onOpenChange={setOpenModal}
        onClose={() => { setOpenModal(false); setSelectedImage(null) }}
        variant="large"
      // className={isVerticalModal ? "w-[501px] h-[660px]" : ""}
      // variant={!isVerticalModal ? "large" : ""}
      >
        {selectedImage &&
          <div className="flex flex-row p-10 gap-16">
            <img className="w-[452px] rounded-lg" src={selectedImage.outputs[0].permalink} />
            <div className="flex flex-col justify-center">
              <Heading as="h3">Image Details</Heading>
              <p> {selectedImage.prompt}</p>
              <p><span className="font-semibold">Resolution:</span> {selectedImage.aspect_ratio}</p>
              {/* TODO model names */}
              <p className="font-semibold">License: <Button className="text-black font-semibold" variant="link"><>Apply for a license <IconArrowRight /> </></Button></p>
              <div className="w-full flex justify-between">
                <Button variant="outline" className="text-black" onClick={() => navigate(`/image/editor/${selectedImage.task_queue_id}`)}><>Edit image<EditPromptIcon /></></Button>
                <Button variant="outline" className="text-black" onClick={deleteMyImage(selectedImage.task_queue_id || "")}><>Delete image<TrashIcon /></></Button>
              </div>
            </div>
          </div>
        }

      </DialogModal>
      <SubHeader />
      {myGeneratedImages.length === 0 ? <div className="text-white  pl-20"><Heading as="h3">No images saved at this time</Heading></div> :
        <div className="flex flex-row flex-wrap">
          {myGeneratedImages.map((image, index) => (
            <div key={index} onClick={openImageDetails(image)} className={"image-card h-[460px]"}>
              <div className="image-card-inner"
                style={{
                  backgroundImage: image.outputs[0].permalink
                    ? `linear-gradient(180deg, rgba(20, 20, 20, 0.00) 40.22%, #141414 100%), url(${image.outputs[0].permalink})`
                    : "none",
                }}>
                <div className="absolute top-5 right-5">
                  <MiniVerifiedLogo /></div>
              </div>
            </div>

          ))}
        </div>
      }
    </div>
  )

}

export default MyGeneratedImages;