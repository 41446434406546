import React, { useMemo } from "react";
import { useAppSelector } from "../../hooks";
import { USER_TYPES } from "../../lib/constants";
import { NavLink, useLocation } from "react-router-dom";
import cn from "../../lib/utils";
import { buttonVariants } from "../../common/components/ui/button";

type NavigationLink = { name: string; href: string; current: boolean; variant?: string; suffix?: any };

const SubHeader = () => {
  const { profile } = useAppSelector((state: any) => state.user);
  const { projects, projectsSubmittedForApprovalCount } = useAppSelector((state: any) => state.projects);
  const location = useLocation();
  const { pathname } = location;
  const navigation = useMemo<NavigationLink[]>(() => {
    const nav: NavigationLink[] = [];
    if (profile && profile.user_type === USER_TYPES.CREATOR.key) {
      nav.push({
        name: "Projects",
        href: "/project",
        current: pathname === "/project",
        suffix: projects ? (
          <div
            className={cn(
              "h-5 w-5 flex items-center justify-center rounded-full text-xs",
              pathname === "/project" ? "text-white bg-blackish" : "text-black bg-yellow-300"
            )}
          >
            {projects.length}
          </div>
        ) : null,
      });
      nav.push({ name: "My images", href: "/my-images", current: pathname === "/my-images" });
      nav.push({ name: "Find Talent", href: "/dashboard", current: pathname === "/dashboard" });
      nav.push({ name: "Verify license", href: "/check-license", current: pathname === "/check-license" });
    }
    if (profile && profile.user_type === USER_TYPES.RIGHTS_HOLDER.key) {
      nav.push({ name: "My Talent", href: "/dashboard", current: pathname === "/project" });
      nav.push({
        name: "Projects",
        href: "/project",
        current: pathname === "/project",
        suffix: projectsSubmittedForApprovalCount ? (
          <div
            className={cn(
              "h-5 w-5 flex items-center justify-center rounded-full text-xs",
              pathname === "/project" ? "text-white bg-blackish" : "text-black bg-brand-yellow"
            )}
          >
            {projectsSubmittedForApprovalCount}
          </div>
        ) : null,
      });
      nav.push({ name: "My images", href: "/my-images", current: pathname === "/my-images" });
      nav.push({ name: "Verify license", href: "/check-license", current: pathname === "/check-license" });
    }
    return nav;
  }, [profile, projects, projectsSubmittedForApprovalCount]);

  return (
    <div className="sub_header page_header">
      {navigation.map((item) => (
        <NavLink
          key={item.name}
          to={item.href}
          className={({ isActive }) =>
            cn(
              "flex items-center gap-2 btn",
              isActive
                ? buttonVariants({ variant: "primary", size: "sm" })
                : buttonVariants({ variant: "outline", size: "sm" })
            )
          }
        >
          {item.name}
          {item.suffix}
        </NavLink>
      ))}
    </div>
  );
};
export default SubHeader;
