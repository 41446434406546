import React from "react";
import { Button } from "../../../common/components/ui/button";

export interface StepNavigatorProps {
    steps: any[],
    activeStep: number,
    onStepChange: (nextStep: number) => void
}

const StepNavigator: React.FC<StepNavigatorProps> = ({ steps, activeStep, onStepChange }) => {

    return (
        <div>
            <div className="flex flex-col md:flex-row gap-4 flex-wrap">
                {steps.map((tab: any, index: number) => (
                    <div key={index} className="flex items-center">
                        <Button
                            type="button"
                            variant={activeStep === index ? "primary-negative" : "outline-negative"}
                            onClick={() => onStepChange(index)}
                        >
                            {`${index + 1}. ${tab.heading}`}
                        </Button>
                        {/* Only show the vertical line on desktop */}
                        {index < steps.length - 1 && (
                            <div className="hidden md:block h-[2px] w-8 bg-primary-foreground mx-4"></div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )

}

export default StepNavigator;