import React, { useState } from "react";
import ImageCard from "./image_card";
import { GenerativeModel } from "../models/image_generator";
import cn from "../../../lib/utils";

const ModelSelect = ({ modelList, handleModelSelect, selectedModel }: { modelList?: any, handleModelSelect: any, selectedModel: any }) => {
    const [searchText, setSearchText] = useState<string>("");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    return (
        <div>
            <input
                className={cn("wizard-text-input focus:border-b-brand-yellow", "text-black")}
                style={{ maxWidth: "1160px" }}
                type="text"
                placeholder="Search by keyword"
                aria-label="Search by keyword"
                value={searchText}
                onChange={handleSearchChange}
            />
            <div className="flex pt-[52px] -mx-20 md:-mx-[120px]">
                {modelList.map((image: GenerativeModel) => (
                    <ImageCard key={image.id} imageModel={image}
                        imageAction={() => handleModelSelect(image)}
                        selected={selectedModel?.id === image.id} />
                ))}
            </div>
        </div>
    )

}

export default ModelSelect;