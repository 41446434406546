import React from "react";
import Heading from "../../common/components/heading";
import loadinganimation from "../../common/components/ui/loadinganimation.gif";

const LoadingPage = ({ progressMessage, percentComplete }: { progressMessage?: string; percentComplete?: number }) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${loadinganimation})`,
            backgroundSize: "cover",
            paddingTop: "450px",
            backgroundPosition: "center top",
            height: "100vh",
            width: "100vw",
        }}>
            {percentComplete && <Heading> {percentComplete}% </Heading>}
            <p className="italic">{progressMessage}</p>
        </div>
    )

}

export default LoadingPage;