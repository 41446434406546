import React from "react";
import { GenerativeModel } from "../models/image_generator";
import "./image_details.css";
import { PencilIcon, IconCheckMark } from "../../../common/icons/icons";

const ImageCard = ({ imageModel, forImageDetailsPage, selected, imageAction }: { imageModel: GenerativeModel, forImageDetailsPage?: boolean, selected?: boolean, imageAction?: () => void }) => {
    return (
        <div className={`image-card ${forImageDetailsPage ? "rounded-xl h-[472px]" : "h-[460px]"}`}>
            <div className="image-card-inner"
                style={{
                    backgroundImage: imageModel?.thumbnail_image
                        ? `linear-gradient(180deg, rgba(20, 20, 20, 0.00) 40.22%, #141414 100%), url(${imageModel.thumbnail_image})`
                        : "none",
                    backgroundColor: imageModel?.thumbnail_image ? "transparent" : "#161618",
                }}>
                <div className="action-icon" onClick={imageAction}>
                    {forImageDetailsPage ?
                        <div className="bg-brand-yellow h-full rounded-full flex items-center justify-center">
                            <PencilIcon /></div>
                        :
                        <div
                            className={`h-full rounded-full flex items-center justify-center ${selected ? "bg-brand-yellow" : ""
                                }`}
                        >
                            {selected && <IconCheckMark />}
                        </div>}
                </div>
                <div className="info-block">
                    <div className="category">{imageModel.group_name}</div>
                    <div className="name">{imageModel.name}</div>
                </div>
            </div>
        </div>
    )

}

export default ImageCard;