import React from "react";
import StepNavigator, { StepNavigatorProps } from "./step_navigator";
import Heading from "../../../common/components/heading";

interface StepWrapperProps extends StepNavigatorProps {
    children: React.ReactNode;
    optional?: boolean;
    heading: string;
}

const StepWrapper: React.FC<StepWrapperProps> = ({ children, optional, heading, ...StepNavigatorProps }) => {
    return (
        <div>
            <StepNavigator {...StepNavigatorProps} />
            <div className="text-md font-semibold pt-[60px] pb-5">
                {optional ? "Optional" : "Required"}
            </div>
            <Heading as="small-h1">{heading}</Heading>
            {children}
        </div>
    )
}

export default StepWrapper;
